<template>
  <div>
    <Necker></Necker>
    <div class="container py-16 leading-loose">
      <div>
        <img src="@/assets/imgs/about-title.png" alt="" />
        <h2 class="text-2xl mt-4">
          JODY陪你一起，利用「數字化」破解考試、輕鬆闖關!!
        </h2>
        <div class="text-xl font-bold mt-12">
          數據要經過「分析」才能發揮價值!!
        </div>
        <div class="mt-4 text-gray">
          為什麼Curry的三分球無死角……?? <br />
          為什麼美國職棒大聯盟投捕間對每一個打者的配球都不同…..?? <br />
          想要成功闖關、邁向卓越→除了持續不斷的練習，更重要的關鍵其實是「方向要正確」→知道自己的弱點在哪，然後針對弱點加強，不但有效而且省時。
        </div>
      </div>

      <div class="flex justify-between mt-12 md:flex-col md:items-center">
        <div class="w-[30%] md:w-auto">
          <img
            src="@/assets/imgs/about01.png"
            alt="Question Bank"
            class="-translate-x-10"
          />
          <div class="text-xl font-bold text-primary">Question Bank</div>
          <div class="mt-3 text-gray">
            近20年、各學科各類考試題庫， <br />
            解析雲端資料庫
          </div>
        </div>

        <div class="w-[30%] md:w-auto">
          <img
            src="@/assets/imgs/about02.png"
            alt="Big Data"
            class="-translate-x-10"
          />
          <div class="text-xl font-bold text-primary">Big Data</div>
          <div class="mt-3 text-gray">
            大量練習產出專屬你的大數據， <br />
            精準鎖定弱點加強
          </div>
        </div>

        <div class="w-[30%] md:w-auto">
          <img
            src="@/assets/imgs/about03.png"
            alt="PASS!!"
            class="-translate-x-10"
          />
          <div class="text-xl font-bold text-primary">PASS!!</div>
          <div class="mt-3 text-gray">學習無死角，高分闖關成功!!</div>
        </div>
      </div>

      <div class="mt-24">
        <div class="text-xl font-bold">
          「分析數據」就是JODY網站最大的價值!!
        </div>
        <div class="mt-4 text-gray">
          在這個資料量爆炸的時代，光靠「海量練習」→然後憑「感覺」、「經驗」準備……，很難察覺自己的強弱點。很多時候只是不斷重複練習早就已經很熟練的單元，根本沒有對症下藥，結果就是已經很努力，但還是闖關失敗…。

          <br /><br />
          JODY網站的進化，在於利用資料庫，將每一學科、每份考卷、每一題都分門別類。演練過一定數量後，就會逐漸產出極具參考價值的個人化統計分析→
          <span class="text-primary">
            透過客觀的數據可以很精準的鎖定自己的強弱點：減少強項準備時間，把時間/精力專注於加強弱項。更有策略的準備，不但效果更好、而且更加省時，對自己的成長歷程也更有感。</span
          >再善用「個人目標」功能了解各科實力/上榜機率，決不錯失闖關路上最需要的臨門一腳、再也不會高分落榜!!
        </div>
      </div>

      <img src="@/assets/imgs/about04.png" alt="" class="mt-12" />
    </div>
  </div>
</template>

<script setup>
import Necker from '@/views/_necker/Index.vue';
import { provide, ref } from 'vue';

provide('page', {
  breadcrumbs: ref(['關於我們']),
  title: ref('關於我們'),
  pcBg: ref('banner04.png'),
  mobileBg: ref('banner04_m.png'),
});
</script>

<style lang="sass" scoped></style>
